<template>
  <div>
    <b-alert
      variant="success"
      :show="dismissCountDown"
      dismissible
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <div class="alert-body">
        <span><strong>Alteração feita com suscesso!</strong></span>
      </div>
    </b-alert>
    <b-form @submit.prevent>
      <b-row>
        <b-col md="6">
          <b-form-group label="Nome da rodada" label-for="name">
            <b-form-input id="name" :value="round.name" v-model="round.name" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Preço cartela" label-for="card_price">
            <b-form-input
              id="card_price"
              :value="round.card_price"
              v-model="round.card_price"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Premiação inicial total" label-for="initial_total_prize">
            <b-form-input
              id="initial_total_prize"
              :value="round.initial_total_prize"
              v-model="round.initial_total_prize"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Premiação final incial" label-for="final_total_prize">
            <b-form-input
              id="final_total_prize"
              :value="round.final_total_prize"
              v-model="round.final_total_prize"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Porcentagem LINHA" label-for="first_prize_percentage">
            <b-form-input
              id="first_prize_percentage"
              :value="round.first_prize_percentage"
              v-model="round.first_prize_percentage"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Porcentagem LINHA DUPLA"
            label-for="second_prize_percentage"
          >
            <b-form-input
              id="second_prize_percentage"
              :value="round.second_prize_percentage"
              v-model="round.second_prize_percentage"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Porcentagem LINHA BINGO"
            label-for="third_prize_percentage"
          >
            <b-form-input
              id="third_prize_percentage"
              :value="round.third_prize_percentage"
              v-model="round.third_prize_percentage"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Porcentagem destinada a Jackpot"
            label-for="jackpot_percentage"
          >
            <b-form-input
              id="jackpot_percentage"
              :value="round.jackpot_percentage"
              v-model="round.jackpot_percentage"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Bola saida Jackpot" label-for="golden_ball">
            <b-form-input
              id="golden_ball"
              :value="round.golden_ball"
              v-model="round.golden_ball"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Mínimo de cartelas por jogador" label-for="min_cards">
            <b-form-input
              id="min_cards"
              :value="round.min_cards"
              v-model="round.min_cards"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Máximo de cartelas por jogador" label-for="max_cards">
            <b-form-input
              id="max_cards"
              :value="round.max_cards"
              v-model="round.max_cards"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Rodada especial" label-for="special_round">
            <b-form-input
              id="special_round"
              :value="round.special_round"
              v-model="round.special_round"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Premio progressivo" label-for="progressive_prize">
            <b-form-input
              id="progressive_prize"
              :value="round.progressive_prize"
              v-model="round.progressive_prize"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Cashback" label-for="cashback">
            <b-form-input
              id="special_round"
              :value="round.cashback"
              v-model="round.cashback"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Porcentagem de cashback" label-for="cashback_percentage">
            <b-form-select id="cashback_percentage" v-model="round.cashback_percentage">
              <b-form-select-option
                v-for="index in 111"
                :value="index - 1"
                :key="index - 1"
              >
                {{ index - 1 }}
              </b-form-select-option>
            </b-form-select>
            <!--
            <b-form-input
              id="cashback_percentage"
              :value="round.cashback_percentage"
              v-model="round.cashback_percentage"
            />-->
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group label="Data de início da partida" label-for="start_date">
            <datetime v-model="round.start_date"></datetime>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Data de encerramento" label-for="end_date">
            <b-form-input
              id="end_date"
              :value="round.end_date"
              v-model="round.end_date"
            />
          </b-form-group>
        </b-col>
        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            v-on:click="editRound()"
          >
            Submit
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BAlert,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Datetime from "vue-datetime";

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    datetime: Datetime,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      round: [],
      dismissSecs: 2,
      dismissCountDown: 0,
    };
  },
  created() {
    this.getRound();
    console.log(this);
  },
  methods: {
    getRound() {
      this.$http.get("/room/round/" + this.$route.params.roundId).then((res) => {
        this.round = res.data;
      });
    },
    editRound(roundId) {
      this.$http.post("/room/edit-round", this.round).then((res) => {
        this.round = res.data;
        this.showAlert();
      });
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>

<style></style>
